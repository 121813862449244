//_app.js file wraps every single page load. Good for things like headers and footers.

import Theme from "../components/Theme";
import Script from "next/script";
// import Head from "next/head";
import "./styles.css";
import '@aws-amplify/ui-react/styles.css';
import { Authenticator } from '@aws-amplify/ui-react';

import { Amplify } from 'aws-amplify'

//ToDo: Pull this from cdk-outputs programmatically instead of hardcode in params
// WARNING: Each deploy needs to update this. Really bad... we need to do this better.
if (process.env.NEXT_PUBLIC_DOMAIN == "searchexhaust.com") {
  Amplify.configure({
    Auth: {
      region: "us-east-1",
      userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
      userPoolWebClientId: process.env.NEXT_PUBLIC_USER_POOL_WEB_CLIENT_ID,
    },
  });
} else {
  Amplify.configure({
    Auth: {
      region: "us-east-1",
      userPoolId: process.env.NEXT_PUBLIC_DEV_USER_POOL_ID,
      userPoolWebClientId: process.env.NEXT_PUBLIC_DEV_USER_POOL_WEB_CLIENT_ID
    },
  });
}

// if (process.env.NEXT_PUBLIC_DOMAIN == "chart.searchexhaust.com") { // These were created by chart.searchexhaust.com stack
//   Amplify.configure({
//     Auth: {
//       region: "us-east-1",
//       userPoolId: process.env.NEXT_PUBLIC_DEV_USER_POOL_ID,
//       userPoolWebClientId: process.env.NEXT_PUBLIC_DEV_USER_POOL_WEB_CLIENT_ID,
//     },
//   });
// }


// Amplify.configure({
//   aws_cognito_region: 'us-east-1', // (required) - Region where Amazon Cognito project was created
//   aws_user_pools_id: 'us-east-1_cIo4Mf3bN', // (optional) -  Amazon Cognito User Pool ID
//   aws_user_pools_web_client_id: '4ti2cummskhe82kvvk419r231d', // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
// });

// Amplify.configure({
//   aws_project_region: 'us-east-1' // (optional) Default region for project
// });

// Context maintains theme settings across pages
import ThemeProvider from "../components/ThemeProvider";
import SearchProvider from "../components/SearchProvider";

function SearchExhaust({ Component, pageProps }) {
  return (
    <>
      {/* Google Analytics */}
      <Script id="google-tag-manager" strategy="worker">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-P3DWGQF');
      `}
      </Script>

      <Script id="Adsense-id" async
        onError={(e) => { console.error("AdSense Script failed to load", e); }}
        strategy="worker"
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5145772591788713"
        crossorigin="anonymous"
      />

      {/* Google Ads */}
      {/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5145772591788713"
          crossorigin="anonymous"></script> */}

      {/* <Script
          id="Adsense-id"
          strategy="lazyOnload"
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5145772591788713"
        /> */}


      <Authenticator.Provider>
        <ThemeProvider>
          <SearchProvider>
            <Theme>
              <Component {...pageProps} />
            </Theme>
          </SearchProvider>
        </ThemeProvider>
      </Authenticator.Provider>
    </>
  );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

export default SearchExhaust;
